import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import type { ContactStoryblok } from "@/component-types-sb";

type ContactProps = {
  blok: ContactStoryblok;
};

const Contact = ({ blok }: ContactProps) => (
  <article
    {...storyblokEditable(blok)}
    className="container max-w-7xl mx-auto md:px-8"
  >
    {blok.body?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </article>
);

export default Contact;
