"use client";

import Link from "next/link";
import { StoryblokStory } from "storyblok-generate-ts";
import { CheckSquare, Square } from "lucide-react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { List } from "@/components/layout/List";
import type { LessonStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

type ChapterGroup = { [key: string]: LessonStoryblok[] };

const isStringArray = (array: unknown[]): array is string[] => {
  return array.every((element) => typeof element === "string");
};

const groupByChapter = (
  items: StoryblokStory<LessonStoryblok>[]
): ChapterGroup =>
  items?.reduce((acc: any, item) => {
    const chapter = item.content?.chapter;
    if (chapter) {
      if (!acc[chapter]) {
        acc[chapter] = [];
      }

      acc[chapter].push(item);
    }
    return acc;
  }, {});

type ChaptersProps = {
  items?: (StoryblokStory<LessonStoryblok> | string)[];
  completed?: string[];
  chapter?: string | number;
  uuid?: string;
};

const Chapters = ({
  items = [],
  completed = [],
  chapter,
  uuid,
}: ChaptersProps) => {
  const defaultValue = chapter ? chapter.toString() : "";

  const lessonsByChapter = !isStringArray(items)
    ? groupByChapter(items as StoryblokStory<LessonStoryblok>[])
    : {};

  return Object.entries(lessonsByChapter).map(([name, lessons]) => (
    <Accordion
      key={name}
      type="multiple"
      defaultValue={[defaultValue]}
      className="mt-2"
    >
      <AccordionItem value={name}>
        <AccordionTrigger className="bg-sky-25 rounded-sm p-2">
          {name}
        </AccordionTrigger>
        <AccordionContent>
          <List>
            {lessons
              ?.sort((a, b) => a.content?.number - b.content?.number)
              .map((lesson) => (
                <li
                  key={lesson.id}
                  className={cn({
                    ["font-normal"]: uuid !== lesson.uuid,
                    ["font-semibold text-gray-800"]: uuid === lesson.uuid,
                  })}
                >
                  <Link
                    href={`/${lesson.full_slug}`}
                    className="w-full flex flex-row gap-x-2 items-center justify-between"
                  >
                    <span className="p-1 text-xs font-mono">
                      {lesson.content?.number}
                    </span>
                    <span className="flex-grow">{lesson.name}</span>
                  </Link>

                  {completed?.includes(lesson?.uuid) ? (
                    <CheckSquare
                      size="24"
                      className="flex-none text-green-600"
                    />
                  ) : (
                    <Square size="24" className="flex-none text-gray-75" />
                  )}
                </li>
              ))}
          </List>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  ));
};

export { Chapters };
