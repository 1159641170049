import { useMemo, cloneElement, Children } from "react";
import type { ReactNode } from "react";
import { cn } from "@/lib/utils";

type ListProps = {
  children: ReactNode;
};

const List = ({ children }: ListProps) => {
  let itemIndex = 0;
  const items = useMemo(
    () =>
      Children.toArray(children).map((item: any) => {
        return cloneElement(item, {
          key: item.key ?? `item-${itemIndex}`,
          value: `item-${itemIndex++}`,
          ...item?.props,
          className: cn(
            "inline-flex items-center gap-x-2 py-3 px-2 text-sm",
            item?.props.className
          ),
        });
      }),
    [children, itemIndex]
  );

  return <ul className="flex flex-col divide-y divide-gray-200">{items}</ul>;
};

export { List };
