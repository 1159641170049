import Image from "next/image";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import { cn } from "@/lib/utils";
import type { ImageTeaserStoryblok } from "@/component-types-sb";

type ImageTeaserProps = {
  blok: ImageTeaserStoryblok;
};

const ImageTeaser = ({ blok }: ImageTeaserProps) => (
  <section
    className={cn(
      "p-4 md:p-14 container max-w-5xl mx-auto grid md:grid-cols-2",
      {
        // @ts-expect-error
        ["bg-sun-100 rounded-2xl p-14 max-w-none"]: blok.background === "sun",
        // @ts-expect-error
        ["bg-sand-100 rounded-2xl p-14 max-w-none"]: blok.background === "sand",
        // @ts-expect-error
        ["bg-sky-100 rounded-2xl p-14 max-w-none"]: blok.background === "sky",
      }
    )}
    {...storyblokEditable(blok)}
  >
    <div
      className={cn("mb-8 ", {
        // @ts-expect-error
        "text-white": blok.background === "sky",
      })}
    >
      <Subline size="tall">{blok.subline}</Subline>
      <Headline as="h3" size="tall">
        {blok.headline}
      </Headline>
      <div className="mt-16">
        {blok.cta?.map((nestedBlok: any) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            className={cn({
              // @ts-expect-error
              "bg-white text-black hover:bg-white": blok.background === "sky",
            })}
          />
        ))}
      </div>
    </div>

    <Image
      src="/images/wave.svg"
      alt=""
      className="w-full h-full max-h-64 aspect-square"
      width={420}
      height={420}
    />
  </section>
);

export default ImageTeaser;
