export const componentName = (name: string) => {
  switch (name) {
    case "course":
      return "Online-Schulung";
    case "article":
      return "Artikel";
    case "interview":
      return "Interview";
    case "lesson":
      return "Lektion";
    case "page":
      return "Seite";
    case "event":
      return "Veranstaltung";
    case "company":
      return "Unternehmen";
    default:
      return "";
  }
};

export const slugify = (str: string, maxLength: number = 100) => {
  return str
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/Ä/g, "Ae")
    .replace(/ä/g, "ae")
    .replace(/Ö/g, "Oe")
    .replace(/ö/g, "oe")
    .replace(/Ü/g, "Ue")
    .replace(/ü/g, "ue")
    .replace(/ß/g, "ss")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .slice(0, maxLength);
};
