// @see https://www.mux.com/blog/interactive-video-transcript

import { clsx } from "clsx";

type CuePoint = { time: number; value: any };

const formatTime = (seconds: number | undefined) => {
  if (seconds == null) return "--:--";
  const date = new Date(0);
  date.setSeconds(seconds);
  const substrStart = seconds / (60 * 60) >= 1 ? 11 : 14;
  const timeString = date.toISOString().substring(substrStart, 19);
  return timeString;
};

const TranscriptCuePoint = ({
  cuePoint,
  onCuePointSelected = () => {},
  active = false,
}: {
  cuePoint: CuePoint;
  onCuePointSelected?: (cuePoint: CuePoint) => void;
  active?: boolean;
}) => {
  const { value, time } = cuePoint;
  return (
    <div
      className={clsx(
        "inline-flex gap-1 px-1 py-0.5 rounded-md text-sm hover:bg-legacy-primary",
        {
          ["bg-legacy-secondary text-white"]: active,
        }
      )}
      onClick={() => onCuePointSelected(cuePoint)}
    >
      <time className="font-mono font-bold group-hover:visible">
        {formatTime(time)}
      </time>
      {value}
    </div>
  );
};

export { TranscriptCuePoint };
export type { CuePoint };
