import Link from "next/link";
import dynamic from "next/dynamic";
import { useEffect, useReducer } from "react";
import { useAuth } from "@clerk/nextjs";
import { ArrowRightIcon } from "lucide-react";
import { storyblokEditable } from "@storyblok/react/rsc";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { SectionHeader } from "@/components/typography/SectionHeader";
import { Headline } from "@/components/typography/Headline";
import { Button } from "@/components/ui/button";
import { VideoSkeleton } from "@/components/layout/Skeleton";
import { Chapters } from "@/components/layout/Chapters";
import { signPlaybackId } from "@/lib/mux/signPlaybackId";
import { useConsent } from "@/lib/hooks/useConsent";
import type {
  CourseStoryblok,
  LessonStoryblok,
  TrainerStoryblok,
} from "@/component-types-sb";

const MuxPlayer = dynamic(() => import("@mux/mux-player-react"), {
  ssr: false,
  loading: () => <VideoSkeleton className="w-full h-full" />,
});

type CourseProps = {
  blok: CourseStoryblok;
};

type Tokens = {
  playback: string;
  thumbnail: string;
};

const Course = ({ blok }: CourseProps) => {
  const { userId } = useAuth();
  const { statistics } = useConsent();

  const [token, updateToken] = useReducer(
    (data: Tokens, partialData: Partial<Tokens>) => ({
      ...data,
      ...partialData,
    }),
    { playback: "", thumbnail: "" }
  );

  useEffect(() => {
    const fetchData = async () => {
      const playback_id = blok.playback_id as string;
      const playback = await signPlaybackId(playback_id, "video");
      const thumbnail = await signPlaybackId(playback_id, "thumbnail");
      updateToken({
        playback,
        thumbnail,
      });
    };
    fetchData();
  }, [blok.playback_id]);

  const trainer = blok.trainer as unknown as TrainerStoryblok;
  const lesson = blok.lessons?.[0] as unknown as LessonStoryblok;

  return (
    <article
      className="pt-12 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex md:flex-row flex-col gap-4 md:divide-x-2 divide-sand-50"
      {...storyblokEditable(blok)}
    >
      <div className="w-3/4 flex flex-col gap-4">
        <SectionHeader
          tagline="Online-Kurs"
          headline={blok.title}
          className="mb-2"
        />

        <p className="text-xl mb-4">{blok.description}</p>

        {blok.playback_id && token.playback && token.thumbnail && (
          <MuxPlayer
            streamType="on-demand"
            playbackId={blok.playback_id}
            tokens={token}
            metadataVideoTitle={blok.title}
            metadataViewerUserId={userId || undefined}
            preload="auto"
            className="aspect-video"
            disableCookies={!statistics}
            accentColor="#fbc02d"
          />
        )}

        {lesson && (
          <Link href={`/${lesson.full_slug}`}>
            <Button className="rounded-full">
              <ArrowRightIcon size="16" className="mr-2" />
              {lesson.name}
            </Button>
          </Link>
        )}
      </div>

      <aside className="px-4 py-2">
        <section className="max-w-xs flex flex-col gap-2 mb-8">
          <div className="inline-flex items-center space-x-4">
            <Avatar>
              <AvatarImage src={trainer.content?.image?.filename} alt="" />
              <AvatarFallback>{trainer.name}</AvatarFallback>
            </Avatar>
            <div>
              <Headline as="h2" size="tall">
                Trainer
              </Headline>
              <h3 className="font-semibold tracking-wider">{trainer.name}</h3>
            </div>
          </div>
          <p className="text-sm text-gray-500">
            {trainer.content?.description}
          </p>
        </section>

        <section className="max-w-xs flex flex-col mb-4">
          <Headline as="h2" size="tall" className="mb-4">
            Lektionen
          </Headline>
          <Chapters items={blok.lessons} />
        </section>
      </aside>
    </article>
  );
};

export default Course;
