import Link from "next/link";
import { ArrowRightIcon } from "lucide-react";
import { storyblokEditable } from "@storyblok/react/rsc";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Headline } from "@/components/typography/Headline";
import { CopyText } from "@/components/typography/CopyText";
import { Icon } from "@/components/typography/Icon";
import type { IconName } from "@/components/typography/Icon";
import type { FeatureItemStoryblok } from "@/component-types-sb";

type FeatureItemProps = {
  blok: FeatureItemStoryblok;
  variant?: "list" | "card";
};

const FeatureItem = ({ blok, variant = "card" }: FeatureItemProps) => {
  return (
    <>
      {variant === "card" && (
        <Card {...storyblokEditable(blok)} className="rounded-2xl border-none">
          <CardHeader>
            <Icon name={blok.icon as IconName} className="w-12 h-12" />
          </CardHeader>
          <CardContent>
            {blok.headline && (
              <Headline as="h5" size="regular" className="block">
                {blok.headline}
              </Headline>
            )}
            <CopyText>{blok.text}</CopyText>
          </CardContent>
          <CardFooter>
            <Link
              href={blok?.cta?.[0]?.link?.cached_url || "#"}
              className="flex space-x-6 items-center text-xs pl-6 pr-4 py-3"
            >
              {blok?.cta?.[0]?.label}
              <ArrowRightIcon className="ml-1 h-4 w-4" />
            </Link>
          </CardFooter>
        </Card>
      )}

      {variant === "list" && (
        <li {...storyblokEditable(blok)}>
          <Icon name={blok.icon as IconName} className="w-12 h-12 mb-6" />
          {blok.headline && (
            <Headline as="h3" size="regular" className="block">
              {blok.headline}
            </Headline>
          )}
          <CopyText>{blok.text}</CopyText>
        </li>
      )}
    </>
  );
};

export default FeatureItem;
