import type { ComponentProps } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { renderRichText } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { ArrowRightIcon } from "lucide-react";
import { useAuth } from "@clerk/nextjs";

import { Button } from "@/components/ui/button";
import { Video } from "@/components/media/Video";
import { Avatar } from "@/components/layout/Avatar";
import { Headline } from "@/components/typography/Headline";
import { RichText } from "@/components/typography/RichText";
import type { TextVideoStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

import { VideoSkeleton } from "@/components/layout/Skeleton";
import { useConsent } from "@/lib/hooks/useConsent";

const MuxPlayer = dynamic(() => import("@mux/mux-player-react"), {
  ssr: false,
  loading: () => <VideoSkeleton className="w-full h-full" />,
});

type TextVideoProps = { blok: TextVideoStoryblok } & ComponentProps<"article">;

const TextVideo = ({ blok, ...rest }: TextVideoProps) => {
  const { userId } = useAuth();
  const { statistics } = useConsent();
  const team = blok.team as any;

  return (
    <>
      {blok.text && blok.headline ? (
        <article
          className={cn(
            "bg-white rounded-3xl flex flex-col-reverse md:flex-row gap-14 p-6 mb-8",
            {
              ["md:flex-row-reverse"]: blok.reverse,
            },
            rest.className
          )}
          {...storyblokEditable(blok)}
        >
          <div className="md:w-3/5 flex p-4 md:p-14 flex-col items-start">
            <Headline as="h3" size="medium" className="mb-4">
              {blok.headline}
            </Headline>
            <RichText
              html={renderRichText(blok.text)}
              className="text-xl mb-4"
            />

            {team && (
              <Avatar
                image={team.content?.image?.filename}
                name={team.content?.subheadline}
                text={team.content?.headline}
              />
            )}

            {blok.link && blok.button && (
              <Link href={blok.link.cached_url || "#"}>
                <Button className="rounded-full text-sm">
                  <ArrowRightIcon className="mr-1 h-4 w-4" />
                  {blok.button}
                </Button>
              </Link>
            )}
          </div>
          <div className="md:w-2/5 bg-white rounded-2xl flex items-center">
            <Video fallback="/images/shape.svg">
              <source src={blok.source} type="video/webm" />
            </Video>
          </div>
        </article>
      ) : (
        <article
          className={cn("bg-white rounded-3xl p-6 mb-8", rest.className)}
        >
          <MuxPlayer
            streamType="on-demand"
            playbackId={blok.playback_id}
            metadata={{
              video_title: blok.title,
              viewer_user_id: userId,
            }}
            preload="auto"
            className="aspect-video"
            disableCookies={!statistics}
            accentColor="#D3C4AC"
          />
        </article>
      )}
    </>
  );
};

export default TextVideo;
