import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Headline } from "@/components/typography/Headline";
import { CopyText } from "@/components/typography/CopyText";
import type { LogoListStoryblok } from "@/component-types-sb";

type LogoListProps = {
  blok: LogoListStoryblok;
};

const LogoList = ({ blok }: LogoListProps) => (
  <div
    {...storyblokEditable(blok)}
    className="container md:max-w-7xl md:mx-auto flex flex-col items-center gap-4 mx-auto px-6 lg:px-8 md:py-12 py-6"
  >
    <Headline as="h3" size="tall">
      {blok.headline}
    </Headline>
    <CopyText>{blok.text}</CopyText>

    <Carousel
      id="logo-list"
      className="basis-1/2 md:basis-1/3 lg:basis-1/5 flex items-center"
      opts={{
        loop: true,
        slidesToScroll: 5,
      }}
    >
      <CarouselContent>
        {blok.items?.map((nestedBlok: any) => (
          <CarouselItem key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  </div>
);

export default LogoList;
