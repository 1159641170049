"use client";

import { cn } from "@/lib/utils";
import { track } from "@vercel/analytics";
import Link from "next/link";
import type { ComponentProps } from "react";
import { useFormState } from "react-dom";
import { ArrowRightIcon } from "lucide-react";

import { Input } from "@/components/ui/input";
import { SubmitButton } from "@/components/forms/SubmitButton";
import { subscribe } from "@/app/email/newsletter/actions";

type EmailFormProps = {
  link?: string;
} & Omit<ComponentProps<"form">, "action">;

const EmailForm = ({ link, ...rest }: EmailFormProps) => {
  const [state, formAction] = useFormState(subscribe, {});

  return (
    <form action={formAction} className={cn("mt-8", rest.className)}>
      <fieldset className="flex items-center">
        <Input
          name="email"
          type="email"
          autoComplete="email"
          placeholder="Deine E-Mail"
          className="flex-grow bg-transparent rounded-full py-3 px-6 border border-black"
          required
        />
        <SubmitButton
          className="rounded-full aspect-square"
          size="icon"
          onClick={() => {
            track("Newsletter submit");
          }}
        >
          <ArrowRightIcon className="h-6 w-6" />
          <span className="sr-only">Absenden</span>
        </SubmitButton>
      </fieldset>

      <div className="mx-auto flex flex-col gap-4 justify-center text-center">
        {link && (
          <div className="mt-3 text-center text-sm text-gray-600 gap-1">
            Mit Absenden des Formulars stimme ich den{" "}
            <Link href={link}>Datenschutzbestimmungen</Link> zu
          </div>
        )}

        <p aria-live="polite" className="sr-only">
          {state?.error}
        </p>
      </div>
    </form>
  );
};

export { EmailForm };
