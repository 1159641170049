import type { ComponentProps } from "react";
import Link from "next/link";
import Image from "next/image";

import { Card, CardContent, CardDescription } from "@/components/ui/card";
import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import { Tag } from "@/components/typography/Tag";
import { cn } from "@/lib/utils";

type ImageCardProps = {
  tagline?: string;
  headline: string;
  text?: string;
  href: string;
  image?: string;
  tags?: (number | string)[];
  size?: "normal" | "large";
  mask?: boolean;
} & ComponentProps<"div">;

const ImageCard = ({
  tagline,
  headline,
  text,
  href,
  image = "/images/shape.svg",
  tags = [],
  size = "normal",
  mask = false,
  ...rest
}: ImageCardProps) => (
  <Card
    className={cn(
      "bg-sand-10 rounded-2xl border-none overflow-hidden card-rotate",
      rest.className
    )}
  >
    <Link href={href} aria-label="Weitere Informationen">
      <Image
        src={image}
        alt=""
        width={400}
        height={300}
        className="w-full h-80 aspect-[4/3] object-cover"
        style={{ clipPath: mask ? "url(#mask1)" : undefined }}
      />
      <CardContent className="p-6 space-y-4">
        <div>
          <header className="font-normal">
            <Subline>{tagline}</Subline>
            <Headline as="p" size="regular">
              {headline}
            </Headline>
          </header>
          <CardDescription>{text}</CardDescription>
        </div>
        <div className="flex justify-start items-center space-x-2">
          {tags?.map((name: number | string, index: number) => (
            <Tag
              key={index}
              label={name.toLocaleString()}
              href={`/search?tag=${name}`}
              size="small"
            />
          ))}
        </div>
      </CardContent>
    </Link>
  </Card>
);

export { ImageCard };
