import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { Lightbulb, Info, TriangleAlert } from "lucide-react";

import { RichText } from "@/components/typography/RichText";
import type { InfoBoxStoryblok } from "@/component-types-sb";

import { Alert, AlertDescription } from "@/components/ui/alert";

type InfoBoxPops = {
  blok: InfoBoxStoryblok;
};

const InfoBox = ({ blok }: InfoBoxPops) => {
  return (
    <Alert
      {...storyblokEditable(blok)}
      variant={blok.type === "warning" ? "destructive" : "default"}
    >
      {blok.type === "tip" && <Lightbulb size={24} />}
      {blok.type === "info" && <Info size={24} />}
      {blok.type === "warning" && <TriangleAlert size={24} />}

      {/* <AlertTitle></AlertTitle> */}
      <AlertDescription>
        <RichText html={renderRichText(blok.text)} />
      </AlertDescription>
    </Alert>
  );
};

export default InfoBox;
