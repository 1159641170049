import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

export const Shape2 = ({ ...rest }: SvgProps) => (
  <svg
    width="544"
    height="272"
    viewBox="0 0 544 272"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M108.8 137c0 75.111-24.356 136-54.4 136C24.356 273 0 212.111 0 137 0 61.89 24.356 1 54.4 1c30.044 0 54.4 60.89 54.4 136Z" />
      <path d="M163.2 137c0 75.111-24.356 136-54.4 136-30.044 0-54.4-60.889-54.4-136 0-75.11 24.356-136 54.4-136 30.044 0 54.4 60.89 54.4 136Z" />
      <path d="M272 137c0 75.111-35.62 136-79.56 136-43.94 0-79.56-60.889-79.56-136 0-75.11 35.62-136 79.56-136C236.38 1 272 61.89 272 137ZM385.86 136c0-75.113 22.659-136 50.605-136 27.947 0 50.605 60.887 50.605 136H385.86ZM272 136c0-75.113 22.658-136 50.605-136 27.946 0 50.604 60.887 50.604 136H272ZM544 136c0 75.113-22.658 136-50.605 136-27.946 0-50.604-60.887-50.604-136H544ZM430.14 136c0 75.113-22.659 136-50.605 136-27.947 0-50.605-60.887-50.605-136h101.21Z" />
    </g>
  </svg>
);
