import { lazy, Suspense } from "react";
import Link from "next/link";
import { storyblokEditable } from "@storyblok/react/rsc";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardDescription,
} from "@/components/ui/card";
import { Headline } from "@/components/typography/Headline";
import { CopyText } from "@/components/typography/CopyText";
import { Button } from "@/components/ui/button";
import type { TextCardStoryblok } from "@/component-types-sb";

type TextCardProps = {
  blok: TextCardStoryblok;
};

const iconMap = {
  AnimatedImages: () => import("@/components/icons/animated-images"),
  LocalLibrary: () => import("@/components/icons/local-library"),
  QuickPhrases: () => import("@/components/icons/quick-phrases"),
};

type IconName = keyof typeof iconMap;

type IconProps = {
  name: IconName;
  className?: string;
};

const Icon = ({ name, className }: IconProps) => {
  const LazyIcon = lazy(iconMap[name]);
  return (
    <Suspense>
      <LazyIcon className={className} />
    </Suspense>
  );
};

const TextCard = ({ blok }: TextCardProps) => (
  <Card
    className="rounded-2xl border-none bg-sun-100"
    {...storyblokEditable(blok)}
  >
    <CardHeader className="flex flex-row justify-between items-center">
      <Icon name={blok.icon as IconName} className="w-12 h-12" />
      <CardDescription className="text-xs uppercase">
        {blok.subline}
      </CardDescription>
    </CardHeader>
    <CardContent className="pt-16">
      <Headline as="h3" size="medium" className="mb-2">
        {blok.headline}
      </Headline>
      <CopyText size="small">{blok.text}</CopyText>
    </CardContent>
    <CardFooter>
      <Button asChild className="rounded-full">
        <Link href={blok.link?.cached_url || "#"}>{blok.button}</Link>
      </Button>
    </CardFooter>
  </Card>
);

export default TextCard;
