"use client";

import Link from "next/link";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import type { PressStoryblok } from "@/component-types-sb";

import { Headline } from "@/components/typography/Headline";
import { SectionHeader } from "@/components/typography/SectionHeader";

type PressProps = {
  blok: PressStoryblok;
};

const Press = ({ blok }: PressProps) => {
  return (
    <section {...storyblokEditable(blok)}>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <SectionHeader tagline="Presse" headline={blok.description || ""} />

        <div className="mb-8">
          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>

        <div className="break-container bg-white">
          <div className="container max-w-7xl mx-auto grid gap-8 lg:grid-cols-2 p-4 md:p-14">
            {blok.press_releases?.map((press_release: any) => (
              <Link
                key={press_release._uid}
                href={`/${press_release.full_slug}`}
                aria-label="Zur Pressemeldung"
              >
                <Headline as="h3" size="medium" className="mb-4">
                  {press_release.content?.title}
                </Headline>
                <p className="text-xl mb-4 line-clamp-5">
                  {press_release.content?.except}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Press;
