import type { ComponentPropsWithoutRef } from "react";
import { cn } from "@/lib/utils";

type Props = ComponentPropsWithoutRef<"div"> & {
  html: string;
};

const RichText = ({ html, ...rest }: Props) => (
  <div
    className={cn(
      "break-words hyphens-auto prose max-w-4xl prose-ul:list-image-check [&>ul>li>p]:my-2 prose-a:text-legacy-secondary prose-a:font-normal prose-a:underline hover:prose-a:no-underline prose-a:decoration-2 prose-a:decoration-legacy-secondary prose-blockquote:p-1 prose-blockquote:px-4 prose-blockquote:bg-gray-50 prose-blockquote:leading-relaxed",
      rest.className
    )}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);

export { RichText };
