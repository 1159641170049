import { Fragment } from "react";
import type { ComponentProps, MouseEvent } from "react";
import { cn } from "@/lib/utils";
import confetti from "canvas-confetti";

type RatingProps = {
  value?: number | null;
  onClick?: (event: MouseEvent<SVGSVGElement>, value: number) => void;
  showText?: boolean;
  max?: number;
} & Omit<ComponentProps<"div">, "onClick">;

const Rating = ({
  value = 0,
  max = 5,
  onClick,
  showText = false,
  ...rest
}: RatingProps) => (
  <div className={cn("group flex items-center", rest.className)}>
    {Array(max)
      .fill(0)
      .map((_, index) => (
        <svg
          key={index}
          className={cn("w-4 h-4 text-gray-300 ms-1", {
            ["cursor-pointer peer peer-hover:text-gray-300 group-hover:text-legacy-primary"]:
              onClick,
            ["text-legacy-primary"]: index < Math.round(Number(value)),
          })}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
          onClick={(event) => {
            confetti();
            onClick?.(event, index + 1);
          }}
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      ))}
    {showText && (
      <Fragment>
        <p className="ms-1 text-sm font-medium text-gray-500">
          {Number(value).toPrecision(2)}
        </p>
        <p className="ms-1 text-sm font-medium text-gray-500">von</p>
        <p className="ms-1 text-sm font-medium text-gray-500">{max}</p>
      </Fragment>
    )}
  </div>
);

export { Rating };
