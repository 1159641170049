"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import type { PressReleaseStoryblok } from "@/component-types-sb";

import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import { RichText } from "@/components/typography/RichText";
import { formatDate } from "@/lib/utils/date";
import { cn } from "@/lib/utils";

type PressReleaseProps = {
  blok: PressReleaseStoryblok;
  createdAt: string;
};

const PressRelease = ({ blok, createdAt }: PressReleaseProps) => {
  return (
    <div
      className="flex flex-col justify-between px-4 pt-8 pb-16 lg:pt-16 lg:pb-24 mx-auto max-w-screen-xl"
      {...storyblokEditable(blok)}
    >
      <article
        className={cn(
          "mx-auto w-full max-w-3xl format format-sm sm:format-base lg:format-lg format-blue"
        )}
      >
        <header className="mb-4 lg:mb-6 not-format">
          <Subline>
            <time dateTime={createdAt}>{formatDate(new Date(createdAt))}</time>
          </Subline>
          <Headline as="h1" size="huge" className="mb-4 lg:mb-6">
            {blok.title}
          </Headline>
        </header>

        <p className="lead text-2xl mb-8">{blok.subtitle}</p>
        <p className="lead text-2xl mb-8">{blok.except}</p>

        <RichText html={renderRichText(blok.text)} />
      </article>
    </div>
  );
};

export default PressRelease;
