import Image from "next/image";
import { Star } from "lucide-react";
import { storyblokEditable } from "@storyblok/react/rsc";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import type { TestimonialStoryblok } from "@/component-types-sb";

type TestimonialProps = {
  blok: TestimonialStoryblok;
};

const Testimonial = ({ blok }: TestimonialProps) => {
  const { text, image, headline, subheadline, rating } = blok.content || blok;

  return (
    <Card
      className="rounded-2xl border-none bg-sand-50"
      {...storyblokEditable(blok)}
    >
      <CardHeader>
        <div className="flex justify-end">
          {[...Array(5)].map((_, i) => {
            const isHalfStar = i % 1 !== 0;
            return (
              <Star
                key={i}
                className={`w-5 h-5 ${
                  i < Math.floor(Number(rating))
                    ? "text-black fill-black"
                    : isHalfStar
                    ? "text-black fill-black half-filled"
                    : "text-gray-75"
                }`}
              />
            );
          })}
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-xl">{text}</p>
      </CardContent>
      <CardFooter className="flex items-center">
        <Image
          src={image?.filename}
          alt={image?.alt}
          width={56}
          height={56}
          className="aspect-square w-12 h-12 object-cover rounded-full mr-4"
        />
        <div>
          {headline}
          <div className="text-xs text-gray-75 uppercase">{subheadline}</div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default Testimonial;
