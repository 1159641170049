import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { SectionHeader } from "@/components/typography/SectionHeader";
import { CopyText } from "@/components/typography/CopyText";
import type {
  FeatureSectionNewStoryblok,
  FeatureItemStoryblok,
} from "@/component-types-sb";
import { cn } from "@/lib/utils";

type FeatureSectionProps = {
  blok: FeatureSectionNewStoryblok;
};

const ListWrapper = ({
  items,
  shouldWrap,
}: {
  items: FeatureItemStoryblok[] | undefined;
  shouldWrap: boolean;
}) =>
  shouldWrap ? (
    <ol className="grid md:grid-cols-3 gap-6 pt-8">
      {items?.map((nestedBlok) => (
        <StoryblokComponent
          key={nestedBlok._uid}
          blok={nestedBlok}
          variant="list"
        />
      ))}
    </ol>
  ) : (
    <>
      {items?.map((nestedBlok) => (
        <StoryblokComponent
          key={nestedBlok._uid}
          blok={nestedBlok}
          variant="card"
        />
      ))}
    </>
  );

const FeatureSection = ({ blok }: FeatureSectionProps) => (
  <div
    className={cn({
      ["break-container mb-8"]: blok.layout === "full-width",
      ["bg-white"]: blok.background === "white",
    })}
  >
    <section
      className={cn("p-4 md:p-14", {
        ["container max-w-7xl mx-auto"]: blok.layout === "full-width",
      })}
      {...storyblokEditable(blok)}
    >
      <SectionHeader
        tagline={blok.subline}
        headline={blok.headline}
        size="tall"
      />

      {blok.text && (
        <CopyText className="mb-16 max-w-2xl">{blok.text}</CopyText>
      )}

      <ListWrapper items={blok.body} shouldWrap={blok.variant === "list"} />
    </section>
  </div>
);

export default FeatureSection;
