import Link from "next/link";
import type { ComponentProps } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

import { Button as ButtonComponent } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import type { ButtonStoryblok } from "@/component-types-sb";

type ButtonProps = {
  blok: ButtonStoryblok;
} & ComponentProps<"button">;

const Button = ({ blok, ...rest }: ButtonProps) => {
  const href =
    blok?.link.linktype === "email"
      ? `mailto:${blok?.link.email}`
      : blok?.link.linktype === "url"
      ? blok?.link.cached_url
      : blok?.link.anchor
      ? `/${[blok?.link.cached_url, blok.link.anchor].join("#")}`
      : blok?.link.linktype === "story"
      ? `/${blok?.link.cached_url}`
      : "#";

  return (
    <Link
      href={href || "#"}
      target={blok.link?.target || "_self"}
      className="text-base font-normal"
      aria-label="Mehr erfahren"
      {...storyblokEditable(blok)}
    >
      {blok.variant === "link" ? (
        blok.label
      ) : (
        <ButtonComponent
          className={cn(
            "bg-black text-white h-full rounded-full",
            {
              ["h-16 pl-6 pr-4 py-3 bg-sun-100 hover:bg-sun-75 text-black"]:
                blok.variant === "sun",
              ["h-16 px-8 text-xl"]: blok.size === "lg",
              ["text-sm"]: blok.size === "sm",
            },
            rest.className
          )}
          size={blok.size || "default"}
        >
          {blok.label}
          {blok.arrow === "right" && (
            <ArrowRightIcon className="ml-1 h-4 w-4" />
          )}
        </ButtonComponent>
      )}
    </Link>
  );
};

export default Button;
