"use client";

import { storyblokInit, apiPlugin } from "@storyblok/react/rsc";
import { ReactElement } from "react";

// General
import Page from "./Page";
import Contact from "./Contact";
import Signup from "./Signup";
import Button from "./Button";
import Cta from "./Cta";
import Hero from "./Hero";
import HeaderVideo from "./HeaderVideo";
import Grid from "./Grid";
import Table from "./Table";
import TableOfContents from "./TableOfContents";
import TableOfContentsItem from "./TableOfContentsItem";
import Slider from "./Slider";
import Tabs from "./Tabs";
import AssetLink from "./AssetLink";
import FileLink from "./FileLink";

// Landingpage
import FeatureSection from "./FeatureSection";
import FeatureListCard from "./FeatureListCard";
import FeatureListText from "./FeatureListText";
import FeatureList from "./FeatureList";
import FeatureItem from "./FeatureItem";
import TopicSection from "./TopicSection";
import ResultSection from "./ResultSection";
import ContentSection from "./ContentSection";
import TextSection from "./TextSection";
import TextContent from "./TextContent";
import TextImage from "./TextImage";
import TextVideo from "./TextVideo";
import TextCard from "./TextCard";
import ContentCard from "./ContentCard";
import ImageCard from "./ImageCard";
import ImageTeaser from "./ImageTeaser";
import NewsletterSection from "./NewsletterSection";

// Marketing
import Testimonial from "./Testimonial";
import TestimonialSection from "./TestimonialSection";
import Blog from "./Blog";
import Article from "./Article";
import Interviews from "./Interviews";
import Interview from "./Interview";
import FaqSection from "./FaqSection";
import LogoList from "./LogoList";
import Press from "./Press";
import PressRelease from "./PressRelease";
import Ad from "./Ad";

// Course
import Library from "./Library";
import Course from "./Course";
import Lesson from "./Lesson";
import Evaluation from "./Evaluation";
import Video from "./Video";
import VideoCollection from "./VideoCollection";

// Layout
import FallbackComponent from "./FallbackComponent";

storyblokInit({
  accessToken: process.env.storyblokApiToken,
  use: [apiPlugin],
  apiOptions: {
    region: "eu",
  },
  components: {
    // General
    page: Page,
    contact: Contact,
    signup: Signup,
    button: Button,
    cta: Cta,
    hero: Hero,
    header_video: HeaderVideo,
    grid: Grid,
    table: Table,
    table_of_contents: TableOfContents,
    table_of_contents_item: TableOfContentsItem,
    slider: Slider,
    tabs: Tabs,
    asset_link: AssetLink,
    file_link: FileLink,

    // Landingpage
    feature_section_new: FeatureSection,
    feature_list_card: FeatureListCard,
    feature_list_text: FeatureListText,
    feature_list: FeatureList,
    feature_item: FeatureItem,
    topic_section: TopicSection,
    result_section: ResultSection,
    content_section: ContentSection,
    text_section: TextSection,
    text_content: TextContent,
    text_image: TextImage,
    text_video: TextVideo,
    text_card: TextCard,
    content_card: ContentCard,
    image_card: ImageCard,
    image_teaser: ImageTeaser,
    newsletter_section: NewsletterSection,
    // Marketing
    blog: Blog,
    article: Article,
    interviews: Interviews,
    interview: Interview,
    testimonial: Testimonial,
    testimonial_section: TestimonialSection,
    faq_section: FaqSection,
    logo_list: LogoList,
    press: Press,
    press_release: PressRelease,
    ad: Ad,
    // Course
    library: Library,
    course: Course,
    lesson: Lesson,
    evaluation: Evaluation,
    video: Video,
    video_collection: VideoCollection,
  },
  enableFallbackComponent: true,
  customFallbackComponent: FallbackComponent,
});

type StoryblokProviderProps = {
  children: ReactElement;
};

export default function StoryblokProvider({
  children,
}: StoryblokProviderProps) {
  return children;
}
