import type { ComponentProps } from "react";

import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import type { HeadlineSize } from "@/components/typography/Headline";
import { cn } from "@/lib/utils";

type SectionSize = HeadlineSize;

type SectionHeaderProps = {
  tagline: string;
  headline: string;
  size?: SectionSize;
} & ComponentProps<"header">;

const SectionHeader = ({
  tagline,
  headline,
  size = "tall",
  ...rest
}: SectionHeaderProps) => (
  <header className={cn("mb-16", rest.className)}>
    <Subline size="tall">{tagline}</Subline>
    <Headline as="h2" size={size} className="max-w-4xl">
      {headline}
    </Headline>
  </header>
);

export { SectionHeader };
export type { SectionSize };
