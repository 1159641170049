import Link from "next/link";
import { FileDown } from "lucide-react";
import { storyblokEditable } from "@storyblok/react/rsc";

import type { FileLinkStoryblok } from "@/component-types-sb";

type FileLinkProps = {
  blok: FileLinkStoryblok;
};

const FileLink = ({ blok }: FileLinkProps) => (
  <Link
    href={blok.link?.cached_url || "#"}
    aria-label="Datei öffnen"
    className="inline-flex gap-2 items-center text-sm decoration-legacy-secondary decoration-2 hover:underline"
    {...storyblokEditable(blok)}
  >
    <FileDown size="21" className="flex-none text-legacy-secondary" />

    {blok?.title}
  </Link>
);

export default FileLink;
