import { storyblokEditable } from "@storyblok/react/rsc";
import type { InterviewsStoryblok } from "@/component-types-sb";

import { SectionHeader } from "@/components/typography/SectionHeader";
import { ImageCard } from "@/components/layout/ImageCard";

type InterviewsProps = {
  blok: InterviewsStoryblok;
};

const Interviews = ({ blok }: InterviewsProps) => (
  <section {...storyblokEditable(blok)}>
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <SectionHeader tagline="Interviews" headline={blok.description || ""} />

      <div className="break-container bg-white">
        <div className="container max-w-7xl mx-auto grid gap-8 lg:grid-cols-2 p-4 md:p-14">
          {blok.interviews?.map((interview: any) => (
            <ImageCard
              key={interview.uuid}
              tagline={interview.content?.topic}
              headline={interview.content?.title}
              image={interview.content?.image?.filename}
              href={`/${interview.full_slug}`}
              tags={interview.content?.tags}
            />
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Interviews;
