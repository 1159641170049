import Link from "next/link";
import { ReactNode, Fragment } from "react";
import type { MouseEvent } from "react";

type LinkWrapperProps = {
  as?: "link" | "anchor";
  href?: string;
  className?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  children: ReactNode;
};

export const LinkWrapper = ({
  as = "link",
  href,
  className,
  onClick,
  children,
}: LinkWrapperProps) => {
  const Element = as === "anchor" ? "a" : Link;

  return href && href !== "" ? (
    <Element href={href} className={className} onClick={(e) => onClick?.(e)}>
      {children}
    </Element>
  ) : (
    <Fragment>{children}</Fragment>
  );
};
