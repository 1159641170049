"use client";

import { useFormStatus } from "react-dom";
import { Loader } from "lucide-react";
import { cn } from "@/lib/utils";

import { Button } from "@/components/ui/button";
import type { ButtonProps } from "@/components/ui/button";

type SubmitButtonProps = ButtonProps;

const SubmitButton = ({ children, disabled, ...rest }: SubmitButtonProps) => {
  const { pending } = useFormStatus();

  return (
    <Button
      type="submit"
      className={cn(rest.className)}
      disabled={pending || disabled}
      {...rest}
    >
      {children}

      {pending && <Loader className="h-4 w-4" />}
    </Button>
  );
};

export { SubmitButton };
