import Image from "next/image";

type AvatarProps = {
  image: string;
  name: string;
  text: string;
};

const Avatar = ({ image, name, text }: AvatarProps) => {
  return (
    <div className="flex items-center justify-center space-x-8 mt-8">
      <Image
        src={image}
        alt=""
        width={56}
        height={56}
        className="w-16 h-16 aspect-square object-cover rounded-full"
      />
      <div className="flex flex-col">
        <small className="text-xs uppercase">{name}</small>
        <span>{text}</span>
      </div>
    </div>
  );
};

export { Avatar };
