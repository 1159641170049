"use client";

import Image from "next/image";
import { renderToStaticMarkup } from "react-dom/server";
import { useEffect, useState } from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import type { ArticleStoryblok } from "@/component-types-sb";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Separator } from "@/components/ui/separator";
import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import { ImageCard } from "@/components/layout/ImageCard";
import { RichText } from "@/components/typography/RichText";
import Cta from "@/components/storyblok/Cta";
import InfoBox from "@/components/storyblok/InfoBox";
import ImageTeaser from "@/components/storyblok/ImageTeaser";
import Ad from "@/components/storyblok/Ad";
import Accordion from "@/components/storyblok/Accordion";
import TestimonialSection from "@/components/storyblok/TestimonialSection";
import { formatDate } from "@/lib/utils/date";
import { getArticles } from "@/lib/storyblok/getArticles";
import { cn } from "@/lib/utils";

type ArticleProps = {
  blok: ArticleStoryblok;
  createdAt: string;
};

const Article = ({ blok, createdAt }: ArticleProps) => {
  const [articles, setArticles] = useState<ArticleStoryblok[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getArticles({
        filter_query: {
          _uid: { not_in: blok._uid },
        },
      });
      setArticles(data);
    };
    fetchData();
  }, [blok]);

  const hasHeader = Array.isArray(blok.header) && blok.header.length > 0;

  return (
    <div
      className="flex flex-col justify-between px-4 pt-8 pb-16 lg:pt-16 lg:pb-24 mx-auto max-w-screen-xl"
      {...storyblokEditable(blok)}
    >
      <div
        className={cn({
          ["grid md:grid-cols-3"]: hasHeader,
        })}
      >
        {hasHeader && (
          <aside className="col-span-1 top-12 md:order-last">
            {blok.header?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </aside>
        )}

        <article
          className={cn(
            "mx-auto w-full max-w-3xl format format-sm sm:format-base lg:format-lg format-blue",
            {
              ["col-span-2"]: hasHeader,
            }
          )}
        >
          <header className="mb-4 lg:mb-6 not-format">
            <Subline>
              <time dateTime={createdAt}>
                {formatDate(new Date(createdAt))}
              </time>
            </Subline>
            <Headline as="h1" size="huge" className="mb-4 lg:mb-6">
              {blok.title}
            </Headline>
          </header>

          <p className="lead text-2xl mb-8">{blok.subtitle}</p>

          {blok.image?.filename && (
            <Image
              src={blok.image.filename}
              alt={blok.image.alt || ""}
              width={1280}
              height={720}
              className="mb-4"
            />
          )}

          <RichText
            html={renderRichText(blok.text, {
              resolver: (component, blok) => {
                switch (component) {
                  case "cta":
                    return renderToStaticMarkup(
                      <div className="not-prose max-w-xl mx-auto my-8 text-center">
                        <Cta blok={blok} />
                      </div>
                    );
                  case "info_box":
                    return renderToStaticMarkup(<InfoBox blok={blok} />);
                  case "image_teaser":
                    return renderToStaticMarkup(
                      <div className="not-prose">
                        <ImageTeaser blok={blok} />
                      </div>
                    );
                  case "ad":
                    return renderToStaticMarkup(<Ad blok={blok} />);
                  case "accordion":
                    return renderToStaticMarkup(
                      <div className="not-prose">
                        <Accordion blok={blok} />
                      </div>
                    );
                  case "testimonial_section":
                    return renderToStaticMarkup(
                      <div className="not-prose">
                        <TestimonialSection blok={blok} size="regular" />
                      </div>
                    );
                  case "table":
                    return `<table>
                            <thead>
                              <tr>
                                ${blok.table.thead
                                  .map((th: any) =>
                                    `<th>${th.value}</th>`.trim()
                                  )
                                  .join("")}
                              </tr>
                            </thead>
                            <tbody>
                              ${blok.table.tbody
                                .map((tr: any) =>
                                  `
                                <tr>
                                  ${tr.body
                                    .map((td: any) =>
                                      `<td>${td.value}</td>`.trim()
                                    )
                                    .join("")}
                                </tr>`.trim()
                                )
                                .join("")}
                            </tbody>
                          </table>`;
                }
              },
            })}
          />

          {blok.sponsor?.length && (
            <>
              <Separator className="my-4" />
              <h4 className="text-sm font-medium leading-none mb-4">Sponsor</h4>

              {blok.logo?.filename && (
                <Image
                  src={blok.logo.filename}
                  alt={blok.logo.alt || ""}
                  width={200}
                  height={200}
                  className="float-end ml-4 mb-4"
                />
              )}

              <RichText html={renderRichText(blok.sponsor)} />
            </>
          )}
        </article>
      </div>

      <Headline as="p" size="tall" className="py-8">
        Weitere Artikel
      </Headline>

      <Carousel>
        <CarouselContent>
          {articles?.map((nestedBlok) => (
            <CarouselItem className="basis-1/3" key={nestedBlok.id}>
              <ImageCard
                key={nestedBlok.uuid}
                tagline={nestedBlok.content?.topic}
                headline={nestedBlok.content?.title}
                image={nestedBlok.content?.image?.filename}
                href={`/${nestedBlok.full_slug}`}
                tags={nestedBlok.content?.tags}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>

      <div className="mt-32">
        {blok.body?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  );
};

export default Article;
