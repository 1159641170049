"use client";

import { useSearchParams } from "next/navigation";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

import { Tag } from "@/components/typography/Tag";
import { getCategories } from "@/lib/storyblok/getCategories";
import type { CategoryStoryblok } from "@/component-types-sb";

const Categories = () => {
  const [categories, setCategories] = useState<CategoryStoryblok[] | null>([]);

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const query = searchParams.get("category")?.toLocaleLowerCase() || undefined;

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCategories({});
      setCategories(data);
    };
    fetchData();
  }, []);

  return (
    <div className="flex items-center gap-2 flex-wrap overflow-x-auto snap-x snap-mandatory">
      <div>Themenwelten</div>
      <Tag label="Alle" href={pathname} active={!query} />
      {categories?.map((category) => (
        <Tag
          key={category.id}
          label={category.content?.name || ""}
          href={
            query === category.name?.toLocaleLowerCase()
              ? pathname
              : `?category=${category.name?.toLocaleLowerCase()}`
          }
          active={query === category.name?.toLocaleLowerCase()}
        />
      ))}
    </div>
  );
};

export { Categories };
