import Link from "next/link";
import { Fragment } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";

import { SectionHeader } from "@/components/typography/SectionHeader";
import { Button } from "@/components/ui/button";
import { ImageCard } from "@/components/layout/ImageCard";
import { ContentCard } from "@/components/layout/ContentCard";
import type { TopicSectionStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";
import { componentName } from "@/lib/utils/string";

type TopicSectionProps = {
  blok: TopicSectionStoryblok;
};

const TopicSection = ({ blok }: TopicSectionProps) => {
  return (
    <section
      className="bg-white mx-1 md:mx-0 p-4 md:p-14 rounded-3xl"
      {...storyblokEditable(blok)}
    >
      <SectionHeader
        tagline={blok.subline}
        headline={blok.headline}
        className="mb-14"
      />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {blok?.items?.map((nestedBlok: any, index: number) => (
          <Fragment key={nestedBlok.id}>
            {index === 0 && (
              <div className="md:col-span-3">
                <ContentCard
                  tagline={componentName(nestedBlok.content?.component)}
                  headline={nestedBlok.content?.title}
                  text={nestedBlok.content?.description}
                  image={nestedBlok.content?.image?.filename}
                  href={`/${nestedBlok.full_slug}`}
                  tags={nestedBlok.content?.tags}
                  mask
                />
              </div>
            )}

            {index > 0 && (
              <div
                className={cn({
                  ["md:col-span-2 md:row-start-2"]: index === 1,
                  ["md:col-start-3 md:row-start-2"]: index === 2,
                })}
              >
                <ImageCard
                  tagline={componentName(nestedBlok.content?.component)}
                  headline={nestedBlok.content?.title}
                  image={nestedBlok.content?.image?.filename}
                  href={`/${nestedBlok.full_slug}`}
                  tags={nestedBlok.content?.tags}
                  mask={index === 1}
                />
              </div>
            )}
          </Fragment>
        ))}
      </div>

      <footer className="flex items-center justify-center my-8 md:my-16">
        <Link href="/blog">
          <Button className="rounded-full text-base">Alle anzeigen</Button>
        </Link>
      </footer>
    </section>
  );
};

export default TopicSection;
