import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { cn } from "@/lib/utils";

import type { GridStoryblok } from "@/component-types-sb";

type GridProps = {
  blok: GridStoryblok;
};

const Grid = ({ blok }: GridProps) => {
  return (
    <>
      {blok.rows ? (
        <section className="grid gap-8 p-4 md:p-14">
          {blok?.items?.map((nestedBlok: any) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </section>
      ) : (
        <section
          className={cn("grid md:grid-cols-3 gap-6 mb-6", {
            ["grid-cols-1 md:grid-cols-2"]: blok.items?.length === 2,
            ["grid-cols-2 md:grid-cols-4"]: blok.items?.length === 4,
          })}
          {...storyblokEditable(blok)}
        >
          {blok?.items?.map((nestedBlok: any) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </section>
      )}
    </>
  );
};

export default Grid;
