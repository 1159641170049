"use client";

import Image from "next/image";
import React, { useRef, useEffect } from "react";
import type { ReactNode, VideoHTMLAttributes } from "react";
import { cn } from "@/lib/utils";

type VideoPlayerProps = VideoHTMLAttributes<HTMLVideoElement> & {
  fallback?: string;
  children?: ReactNode;
};

const Video: React.FC<VideoPlayerProps> = ({ fallback, children, ...rest }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(videoElement);

    return () => {
      observer.unobserve(videoElement);
    };
  }, []);

  return (
    <video
      ref={videoRef}
      muted
      playsInline
      loop
      preload="metadata"
      {...rest}
      className={cn("w-full h-full", rest.className)}
    >
      {children}

      {fallback && (
        <Image
          src={fallback}
          alt=""
          className="w-full h-full max-h-64 m-8 aspect-square"
          width={420}
          height={420}
        />
      )}
    </video>
  );
};

export { Video };
