import type { ComponentProps } from "react";
import Link from "next/link";
import Image from "next/image";

import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import { Tag } from "@/components/typography/Tag";
// import { Lock } from "@/components/icons";
import { cn } from "@/lib/utils";

type ContentCardProps = {
  tagline?: string;
  headline: string;
  text?: string;
  href: string;
  image?: string;
  tags?: (number | string)[];
  color?: "sun" | "sky";
  reverse?: boolean;
  mask?: boolean;
} & ComponentProps<"article">;

const defaultImage = "/images/shape.svg";

const ContentCard = ({
  tagline,
  headline,
  text,
  href,
  image = defaultImage,
  tags = [],
  color = "sun",
  reverse = false,
  mask = false,
  ...rest
}: ContentCardProps) => (
  <article
    className={cn(
      "rounded-3xl card-rotate",
      {
        "bg-sun-100": color === "sun",
        "bg-sky-75": color === "sky",
      },
      rest.className
    )}
  >
    <Link
      href={href}
      className={cn(
        "flex flex-col md:flex-row",
        {
          "md:flex-row-reverse": reverse,
        },
        rest.className
      )}
      aria-label="Weitere Informationen"
    >
      <div className="md:w-3/5 p-14 flex flex-col">
        <Subline>{tagline}</Subline>
        <Headline as="h3" size="tall" className="font-bold">
          {headline}
        </Headline>

        <p className="text-xl my-4 line-clamp-5">{text}</p>

        <div className="flex items-center gap-2 md:flex-wrap overflow-x-auto snap-x snap-mandatory mt-auto">
          {/* <div className="bg-sand-50 rounded-full w-6 h-6 flex items-center justify-center">
          <Lock className="w-4 h-4" />
        </div>
        <Subline className="flex-grow">Kurs</Subline> */}

          {tags?.map((name: number | string, index: number) => (
            <Tag
              key={index}
              label={name.toLocaleString()}
              href={`/search?tag=${name}`}
            />
          ))}
        </div>
      </div>

      <div className="md:w-2/5 order-first md:order-none min-h-[300px] flex items-center">
        <Image
          src={image}
          alt=""
          width={420}
          height={420}
          className={cn(
            "w-full h-full object-cover object-right rounded-r-2xl",
            {
              "object-contain": image === defaultImage,
              "object-left rounded-l-2xl": reverse,
            }
          )}
          style={{ clipPath: mask ? "url(#mask1)" : undefined }}
        />
      </div>
    </Link>
  </article>
);

export { ContentCard };
