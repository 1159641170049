import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

export const Shape = ({ ...rest }: SvgProps) => (
  <svg
    width="320"
    height="320"
    viewBox="0 0 320 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M0 320c88.573 0 160.376-71.634 160.376-160S88.573 0 0 0v320ZM160.376 320C248.534 320 320 248.366 320 160S248.534 0 160.376 0v320Z"
      fill="currentColor"
    />
  </svg>
);
