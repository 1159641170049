import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";

import { Accordion as AccordionComponent } from "@/components/layout/Accordion";
import { RichText } from "@/components/typography/RichText";
import type { AccordionStoryblok } from "@/component-types-sb";

type AccordionProps = {
  blok: AccordionStoryblok;
};

const Accordion = ({ blok }: AccordionProps) => {
  return (
    <AccordionComponent
      {...storyblokEditable(blok)}
      className="mb-1"
      title={blok?.title || ""}
    >
      <RichText
        html={renderRichText(blok.text)}
        className="text-base leading-7 text-gray-600"
      />
    </AccordionComponent>
  );
};

export default Accordion;
