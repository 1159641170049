import Link from "next/link";
import dynamic from "next/dynamic";
import { useAuth } from "@clerk/nextjs";

import { ChevronRightIcon } from "@heroicons/react/20/solid";

import { storyblokEditable } from "@storyblok/react/rsc";
import type { VideoStoryblok } from "@/component-types-sb";

import { Headline } from "@/components/typography/Headline";
import { CopyText } from "@/components/typography/CopyText";
import { Separator } from "@/components/ui/separator";
import { VideoSkeleton } from "@/components/layout/Skeleton";
import { useConsent } from "@/lib/hooks/useConsent";

const MuxPlayer = dynamic(() => import("@mux/mux-player-react"), {
  ssr: false,
  loading: () => <VideoSkeleton className="w-full h-full" />,
});

type VideoProps = {
  blok: VideoStoryblok;
  playbackToken?: string;
  thumbnailToken?: string;
  transcript?: string;
};

const Video = ({
  blok,
  playbackToken,
  thumbnailToken,
  transcript,
}: VideoProps) => {
  const { userId } = useAuth();
  const { statistics } = useConsent();

  return (
    <article
      className="pt-12 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8"
      {...storyblokEditable(blok)}
    >
      <ol
        className="flex items-center whitespace-nowrap mb-8"
        aria-label="Breadcrumb"
      >
        <li className="inline-flex items-center">
          <Link
            className="flex items-center text-sm text-gray-500 hover:text-legacy-secondary focus:outline-none focus:text-legacy-secondary dark:hover:text-legacy-primary dark:focus:text-legacy-primary"
            href={"/library"}
          >
            Alle Kurse
          </Link>

          <ChevronRightIcon className="w-4 h-4 mx-1 text-gray-400 dark:text-gray-600" />
        </li>
        <li
          className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-gray-200"
          aria-current="page"
        >
          {blok.title}
        </li>
      </ol>

      <div className="flex md:flex-row flex-col gap-4 md:divide-x-2 divide-gray-100 dark:divide-gray-800">
        <div className="basis-3/4 flex flex-col gap-4">
          <MuxPlayer
            streamType="on-demand"
            playbackId={blok.playback_id}
            tokens={{
              playback: playbackToken,
              thumbnail: thumbnailToken,
            }}
            metadataVideoTitle={blok.title}
            metadataViewerUserId={userId || undefined}
            preload="auto"
            className="aspect-video"
            disableCookies={!statistics}
            accentColor="#fbc02d"
          />
          <Headline as="h1" size="huge" className="mb-4">
            {blok.title}
          </Headline>
          <CopyText className="max-w-3xl">{blok.description}</CopyText>

          {transcript && (
            <>
              <Separator className="my-4" />
              <h4 className="text-sm font-medium leading-none mb-4">
                Transcript
              </h4>

              <section className="overflow-y-auto h-96 bg-gray-50 rounded-md p-6 dark:bg-gray-950">
                <blockquote className="italic font-light leading-relaxed text-gray-900 dark:text-gray-100">
                  <svg
                    className="w-8 h-8 text-gray-400 mb-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 14"
                  >
                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                  </svg>
                  <p>{transcript}</p>
                </blockquote>
              </section>
            </>
          )}
        </div>

        <aside className="px-4 basis-1/4 flex flex-col gap-4"></aside>
      </div>
    </article>
  );
};

export default Video;
