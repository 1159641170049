import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Headline } from "@/components/typography/Headline";
import type {
  TableOfContentsStoryblok,
  TableOfContentsItemStoryblok,
  CtaStoryblok,
} from "@/component-types-sb";

type ListPops = {
  blok: TableOfContentsStoryblok;
};

const TableOfContents = ({ blok }: ListPops) => (
  <nav {...storyblokEditable(blok)} className="p-4">
    <Accordion type="single" collapsible className="md:hidden">
      <AccordionItem value={`item-0`} className="mb-4">
        <AccordionTrigger>{blok.headline}</AccordionTrigger>
        <AccordionContent>
          <ol className="p-4 mt-4 space-y-4">
            {blok.items?.map((nestedBlok: TableOfContentsItemStoryblok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </ol>
        </AccordionContent>
      </AccordionItem>
    </Accordion>

    <div className="hidden md:block">
      {blok.headline && (
        <Headline as="p" size="medium">
          {blok.headline}
        </Headline>
      )}

      <ol className="p-4 mt-4 space-y-4">
        {blok.items?.map((nestedBlok: TableOfContentsItemStoryblok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </ol>

      <div className="flex flex-col items-center">
        {blok.cta?.map((nestedBlok: CtaStoryblok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </nav>
);

export default TableOfContents;
