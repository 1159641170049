import type { ReactNode } from "react";

import {
  ForYou,
  PhotoAutoMerge,
  Diversity2,
  ArmingCountdown,
  Diversity1,
  EdgesensorHigh,
} from "@/components/icons";

const ICONS = [
  "ArmingCountdown",
  "EdgesensorHigh",
  "ForYou",
  "PhotoAutoMerge",
  "Diversity2",
  "Diversity1",
];

type IconName = (typeof ICONS)[number];

type IconProps = {
  name?: IconName;
  className?: string;
};

const Icon = ({ name, className }: IconProps): ReactNode => {
  switch (name) {
    case "ArmingCountdown":
      return <ArmingCountdown className={className} />;
    case "ForYou":
      return <ForYou className={className} />;
    case "PhotoAutoMerge":
      return <PhotoAutoMerge className={className} />;
    case "Diversity1":
      return <Diversity1 className={className} />;
    case "Diversity2":
      return <Diversity2 className={className} />;
    case "EdgesensorHigh":
      return <EdgesensorHigh className={className} />;
    default:
      return null;
  }
};

export { Icon };
export type { IconName };
