import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { SectionHeader } from "@/components/typography/SectionHeader";
import { CopyText } from "@/components/typography/CopyText";
import type { ContentSectionStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

type ContentSectionProps = {
  blok: ContentSectionStoryblok;
};

const ContentSection = ({ blok }: ContentSectionProps) => (
  <div
    className={cn({
      ["break-container mb-8"]: blok.layout === "full-width",
      ["bg-white"]: blok.background === "white",
    })}
  >
    <section
      className={cn("p-4 md:p-14", {
        ["container max-w-7xl mx-auto"]: blok.layout === "full-width",
      })}
      {...storyblokEditable(blok)}
    >
      <SectionHeader
        tagline={blok.subline}
        headline={blok.headline}
        size="huge"
      />

      {blok.text && (
        <CopyText className="mb-16 max-w-2xl">{blok.text}</CopyText>
      )}

      {blok.body?.map((nestedBlok: any) => (
        <StoryblokComponent
          key={nestedBlok._uid}
          blok={nestedBlok}
          className={cn({
            ["bg-gray-10"]: blok.background === "white",
          })}
        />
      ))}
    </section>
  </div>
);

export default ContentSection;
