import Image from "next/image";
import { renderRichText } from "@storyblok/react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { RichText } from "@/components/typography/RichText";
import { Headline } from "@/components/typography/Headline";
import { CardDescription } from "@/components/ui/card";
import { Stethoscope } from "@/components/icons";
import type { ImageCardStoryblok } from "@/component-types-sb";

type ImageCardProps = {
  blok: ImageCardStoryblok;
};

const ImageCard = ({ blok }: ImageCardProps) => (
  <article
    className="bg-white rounded-3xl flex flex-col md:flex-row mb-8"
    {...storyblokEditable(blok)}
  >
    <div className="md:w-2/5">
      <Image
        src={blok.image.filename}
        alt={blok.image.alt || ""}
        className="w-full h-full rounded-2xl rounded-b-none md:rounded-b-2xl md:rounded-r-none object-cover"
        width={800}
        height={600}
      />
    </div>
    <div className="p-8 h-full flex flex-col justify-between md:w-3/5">
      <header className="flex flex-row justify-between items-center">
        <Stethoscope className="w-12 h-12" />
        <CardDescription className="text-xs uppercase">
          {blok.subline}
        </CardDescription>
      </header>

      <Headline as="h4" size="medium" className="mt-8 mb-4">
        {blok.headline}
      </Headline>

      <RichText html={renderRichText(blok.text)} className="ml-0 md:ml-6" />

      <div className="flex flex-col md:flex-row gap-4 my-8">
        {blok.cta?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </article>
);

export default ImageCard;
