import Link from "next/link";
// import Video from "next-video";
import dynamic from "next/dynamic";
import { storyblokEditable } from "@storyblok/react/rsc";
import {
  BookOpenIcon,
  ClockIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/button";
import { Headline } from "@/components/typography/Headline";
import videoLoop from "@/videos/1-1 Intro und Einleitung_FINAL.mp4";
import type { HeaderVideoStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

const Video = dynamic(() => import("next-video"));

type HeaderVideoProps = {
  blok: HeaderVideoStoryblok;
};

const HeaderVideo = ({ blok }: HeaderVideoProps) => (
  <div
    className={cn(
      "relative flex items-end justify-center overflow-hidden bg-gray-400",
      {
        ["h-screen"]: blok.layout === "full-height",
        ["h-[65vh]"]: blok.layout === "half-height",
        ["h-[45vh]"]: blok.layout === "one-third-height",
      }
    )}
    {...storyblokEditable(blok)}
  >
    <div className="relative z-30 w-full bg-gradient-to-t from-black to-transparent">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 mb-8 ">
        {blok.headline && (
          <Headline as="h2" size="tall" className="text-white font-bold">
            {blok.headline}
          </Headline>
        )}
        <p className="my-2 text-lg leading-8 text-gray-300">{blok.text}</p>
        <div className="my-2 flex items-end">
          {blok.stats && (
            <dl className="md:grid grid-cols-1 gap-8 sm:mt-8 sm:grid-cols-2 lg:grid-cols-4 hidden">
              <div className="flex flex-col-reverse">
                <dt className="font-light leading-7 text-gray-300">Kapitel</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white flex gap-2 items-center">
                  <BookOpenIcon className="w-6 h-6" />
                  29
                </dd>
              </div>
              <div className="flex flex-col-reverse">
                <dt className="font-light leading-7 text-gray-300">Videos</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white flex gap-2 items-center">
                  <ClockIcon className="w-6 h-6" />
                  180 min
                </dd>
              </div>
              <div className="flex flex-col-reverse">
                <dt className="font-light leading-7 text-gray-300">
                  Weiterempfehlung
                </dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white flex gap-2 items-center">
                  <HandThumbUpIcon className="w-6 h-6" />
                  89 %
                </dd>
              </div>
            </dl>
          )}

          {blok.button && blok.link && (
            <Link href="/courses/diabetes-und-sport">
              <Button>{blok.button}</Button>
            </Link>
          )}
        </div>
      </div>
    </div>

    <div className="absolute z-10 w-auto min-w-full h-full max-w-none">
      <Video
        src={blok.video_url || videoLoop}
        autoPlay
        muted
        loop
        startTime={14}
        playsInline
        controls={false}
        accentColor="#FDFD80"
        className="min-w-full min-h-full aspect-video"
        style={{ "--media-object-fit": "cover" }}
      />
    </div>
  </div>
);

export default HeaderVideo;
