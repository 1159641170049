import { storyblokEditable } from "@storyblok/react/rsc";

import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import { Shape } from "@/components/illustrations/Shape";
import { EmailForm } from "@/components/forms/EmailForm";
import type { NewsletterSectionStoryblok } from "@/component-types-sb";

type NewsletterSectionProps = {
  blok: NewsletterSectionStoryblok;
};

const NewsletterSection = ({ blok }: NewsletterSectionProps) => (
  <section
    className="p-4 md:p-14 container max-w-5xl mx-auto grid md:grid-cols-2"
    {...storyblokEditable(blok)}
  >
    <Shape className="aspect-square w-64 h-64 text-sun-75" />

    <div className="mb-8">
      <Subline size="tall">{blok.subline}</Subline>
      <Headline as="h3" size="tall" className="max-w-lg">
        {blok.headline}
      </Headline>

      <EmailForm link={`/${blok.link?.cached_url}`} />
    </div>
  </section>
);

export default NewsletterSection;
