"use client";

import { useRouter } from "next/navigation";
import { useState, useRef } from "react";
import type { MouseEvent } from "react";
import { useSignUp, useSignIn } from "@clerk/nextjs";
import { OAuthStrategy } from "@clerk/types";

import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import type { Role } from "@/lib/auth/roles";

import { TextInput } from "@/components/forms/TextInput";
import { Checkbox } from "@/components/forms/Checkbox";

type SignupFormProps = {
  role: Role;
};

const SignupForm = ({ role = "user" }: SignupFormProps) => {
  const { signIn } = useSignIn();
  const { isLoaded, signUp, setActive } = useSignUp();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [pendingVerification, setPendingVerification] = useState(false);
  const checkboxTosRef = useRef<HTMLInputElement>(null);
  const checkboxPrivacyRef = useRef<HTMLInputElement>(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const router = useRouter();

  const signInWith = (strategy: OAuthStrategy) => {
    return signIn?.authenticateWithRedirect({
      strategy,
      redirectUrl: "/sso-callback",
      redirectUrlComplete: "/",
    });
  };

  const handleSignIn = async (strategy: OAuthStrategy) => {
    if (!signIn || !signUp) return null;

    // If the user has an account in your application, but does not yet
    // have an OAuth account connected to it, you can transfer the OAuth
    // account to the existing user account.
    const userExistsButNeedsToSignIn =
      signUp.verifications.externalAccount.status === "transferable" &&
      signUp.verifications.externalAccount.error?.code ===
        "external_account_exists";

    if (userExistsButNeedsToSignIn) {
      const res = await signIn.create({ transfer: true });

      if (res.status === "complete") {
        setActive({
          session: res.createdSessionId,
        });
      }
    }

    // If the user has an OAuth account but does not yet
    // have an account in your app, you can create an account
    // for them using the OAuth information.
    const userNeedsToBeCreated =
      signIn.firstFactorVerification.status === "transferable";

    if (userNeedsToBeCreated) {
      const res = await signUp.create({
        transfer: true,
      });

      if (res.status === "complete") {
        setActive({
          session: res.createdSessionId,
        });
      }
    } else {
      // If the user has an account in your application
      // and has an OAuth account connected to it, you can sign them in.
      signInWith(strategy);
    }
  };

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    if (!checkboxTosRef.current?.checked) {
      setError("AGB müssen akzeptiert werden");
      return;
    }

    if (!checkboxPrivacyRef.current?.checked) {
      setError("Datenschutz muss akzeptiert werden");
      return;
    }

    try {
      await signUp.create({
        emailAddress,
        password,
        unsafeMetadata: { role: role },
      });

      // send the email.
      await signUp.prepareEmailAddressVerification({
        strategy: "email_code",
      });

      // change the UI to our pending section.
      setPendingVerification(true);
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
      setError(err.errors[0].longMessage);
    }
  };

  // This verifies the user using email code that is delivered.
  const onPressVerify = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    try {
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code,
      });
      if (completeSignUp.status !== "complete") {
        /*  investigate the response, to see if there was an error
         or if the user needs to complete more steps.*/
        console.log(JSON.stringify(completeSignUp, null, 2));
      }
      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId });
        router.push("/library");
      }
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
    }
  };

  return (
    <>
      {role === "user" && (
        <div className="flex flex-col items-center">
          <button
            type="button"
            onClick={() => handleSignIn("oauth_google")}
            className="text-white bg-gray-100 hover:bg-gray-100/90 focus:ring-4 focus:outline-none focus:ring-gray-100/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-4 h-4 me-2"
            >
              <title>Sign in with Google</title>
              <desc>Google G Logo</desc>
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                className="fill-google-blue"
              ></path>
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                className="fill-google-green"
              ></path>
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                className="fill-google-yellow"
              ></path>
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                className="fill-google-red"
              ></path>
            </svg>
            Weiter mit Google
          </button>

          <Separator className="my-4" />
          <h4 className="text-sm font-medium leading-none mb-4">oder</h4>
        </div>
      )}

      <form className="space-y-6 mb-6" autoComplete="off">
        <input type="hidden" name="role" value={role} />

        <TextInput
          type="email"
          name="email"
          label="E-Mail"
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmailAddress(e.target.value)}
          required
        />
        <TextInput
          type="password"
          name="password"
          label="Passwort"
          minLength={8}
          autoComplete="new-password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Checkbox
          name="tos"
          ref={checkboxTosRef}
          label="AGB gelesen"
          href="/agb"
          defaultChecked={false}
          value={1}
          required
        />
        <Checkbox
          name="privacy"
          ref={checkboxPrivacyRef}
          label="Datenschutzhinweise gelesen"
          href="/datenschutz"
          defaultChecked={false}
          value={1}
          required
        />

        <Button className="w-full" onClick={handleSubmit}>
          Kostenlos registieren
        </Button>
      </form>

      {pendingVerification && (
        <form className="space-y-6">
          <TextInput
            name="code"
            defaultValue={code}
            placeholder="Code..."
            onChange={(e) => setCode(e.target.value)}
          />
          <Button className="w-full" onClick={onPressVerify}>
            Absenden
          </Button>
        </form>
      )}

      <p aria-live="polite" className="text-sm text-red-600">
        {error}
      </p>
    </>
  );
};

export { SignupForm };
