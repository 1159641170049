"use client";

import Link from "next/link";
import { useRouter } from "next/navigation";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { storyblokEditable } from "@storyblok/react/rsc";
import type { VideoCollectionStoryblok } from "@/component-types-sb";
import { useUser } from "@clerk/nextjs";

import { Headline } from "@/components/typography/Headline";
import { Subline } from "@/components/typography/Subline";
import { CopyText } from "@/components/typography/CopyText";
import { ImageCard } from "@/components/layout/ImageCard";

type VideoCollectionProps = {
  blok: VideoCollectionStoryblok;
};

const VideoCollection = ({ blok }: VideoCollectionProps) => {
  const router = useRouter();
  const { user } = useUser();
  const hasPermission =
    !blok.roles || blok.roles?.includes(user?.publicMetadata.role as string);

  if (!hasPermission) {
    router.push("/");
  }

  return (
    <article
      className="pt-12 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8"
      {...storyblokEditable(blok)}
    >
      <ol
        className="flex items-center whitespace-nowrap mb-8"
        aria-label="Breadcrumb"
      >
        <li className="inline-flex items-center">
          <Link
            className="flex items-center text-sm text-gray-500 hover:text-legacy-secondary focus:outline-none focus:text-legacy-secondary dark:hover:text-legacy-primary dark:focus:text-legacy-primary"
            href="/library"
          >
            Alle Kurse
          </Link>

          <ChevronRightIcon className="w-4 h-4 mx-1 text-gray-400 dark:text-gray-600" />
        </li>
        <li
          className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-gray-200"
          aria-current="page"
        >
          {blok.title}
        </li>
      </ol>

      <div className="flex md:flex-row flex-col gap-4 md:divide-x-2 divide-gray-100 dark:divide-gray-800">
        <div className="basis-3/4 flex flex-col gap-4">
          <Subline>Video</Subline>
          <Headline as="h1" size="huge">
            {blok.title}
          </Headline>
          <CopyText className="max-w-3xl">{blok.description}</CopyText>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {blok.videos
              ?.sort((a: any, b: any) => a.content?.number - b.content?.number)
              .map((nestedBlok: any) => (
                <ImageCard
                  key={nestedBlok.uuid}
                  tagline={nestedBlok.content?.topic}
                  headline={nestedBlok.content?.title}
                  image={nestedBlok.content?.image?.filename}
                  href={`/${nestedBlok.full_slug}`}
                  tags={nestedBlok.content?.tags}
                />
              ))}
          </div>
        </div>
        <aside className="px-4 py-2"></aside>
      </div>
    </article>
  );
};

export default VideoCollection;
