import type { ComponentProps } from "react";

import { storyblokEditable } from "@storyblok/react/rsc";

import { SectionHeader } from "@/components/typography/SectionHeader";
import type { SectionSize } from "@/components/typography/SectionHeader";
import type { TestimonialSectionStoryblok } from "@/component-types-sb";
import Testimonial from "./Testimonial";
import { cn } from "@/lib/utils";

type TestimonialSectionProps = ComponentProps<"section"> & {
  blok: TestimonialSectionStoryblok;
  size?: SectionSize;
};

const TestimonialSection = ({
  blok,
  size = "tall",
  ...rest
}: TestimonialSectionProps) => (
  <section
    className={cn("p-4 md:p-14", rest.className)}
    {...storyblokEditable(blok)}
  >
    <SectionHeader
      tagline={blok.subline}
      headline={blok.headline}
      size={size}
    />
    <div className="grid md:grid-cols-3 gap-6">
      {blok.items?.map((nestedBlok: any) => (
        <Testimonial blok={nestedBlok} key={nestedBlok.uuid} />
      ))}
    </div>
  </section>
);

export default TestimonialSection;
