import Image from "next/image";
import { renderRichText } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";

import { RichText } from "@/components/typography/RichText";
import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import { LinkWrapper } from "@/components/utils/LinkWrapper";
// import { Lock } from "@/components/icons";
import { cn } from "@/lib/utils";
import type { ContentCardStoryblok } from "@/component-types-sb";

const defaultImage = "/images/shape.svg";

type ContentCardProps = {
  blok: ContentCardStoryblok;
};

const ContentCard = ({ blok }: ContentCardProps) => (
  <LinkWrapper href={blok.link?.cached_url}>
    <article
      className={cn("rounded-3xl flex flex-col md:flex-row", {
        "md:flex-row-reverse": blok.reverse,
        "bg-sun-100": blok.color === "sun",
        "bg-sky-75": blok.color === "sky",
      })}
      {...storyblokEditable(blok)}
    >
      <div className="md:w-3/5 p-14 flex flex-col">
        <Subline>{blok.subline}</Subline>
        <Headline as="h2" size="tall" className="font-bold">
          {blok.headline}
        </Headline>

        <RichText html={renderRichText(blok.text)} className="text-xl my-4" />

        <div className="flex items-center space-x-2 mt-auto">
          {/* <div className="bg-sand-50 rounded-full w-6 h-6 flex items-center justify-center">
          <Lock className="w-4 h-4" />
        </div>
        <Subline className="flex-grow">Kurs</Subline> */}

          {/* {blok.content?.tags?.map((name: number | string, index: number) => (
          <Tag key={index} label={name.toLocaleString()} href="#" />
        ))} */}
        </div>
      </div>

      <div className="md:w-2/5 order-first md:order-none min-h-[300px] flex items-center">
        <Image
          src={blok.image?.filename || defaultImage}
          alt={blok.image?.alt || ""}
          width={420}
          height={420}
          className={cn(
            "w-full h-auto md:max-h-96 object-cover object-right rounded-r-2xl",
            {
              "object-contain": true,
              "object-left rounded-l-2xl": blok.reverse,
            }
          )}
          style={{ clipPath: blok.mask ? "url(#mask1)" : undefined }}
        />
      </div>
    </article>
  </LinkWrapper>
);

export default ContentCard;
