"use client";

import { useState } from "react";
import { renderRichText } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { SectionHeader } from "@/components/typography/SectionHeader";
import { RichText } from "@/components/typography/RichText";
import { cn } from "@/lib/utils";
import type { FaqSectionStoryblok } from "@/component-types-sb";

type FaqSectionProps = {
  blok: FaqSectionStoryblok;
};

const FaqSection = ({ blok }: FaqSectionProps) => {
  const [value, setValue] = useState("item-0");

  return (
    <section
      className="p-4 md:p-14 mx-auto flex flex-col items-center"
      {...storyblokEditable(blok)}
    >
      <SectionHeader
        tagline={blok.subline}
        headline={blok.headline}
        size="huge"
        className="text-center"
      />

      <Accordion
        type="single"
        collapsible
        onValueChange={setValue}
        className="w-full md:w-[77%]"
      >
        {blok.items?.map((item, index) => (
          <AccordionItem key={index} value={`item-${index}`} className="mb-4">
            <AccordionTrigger
              className={cn(
                "bg-white rounded-t-2xl px-6 py-4 text-xl font-bold hover:no-underline text-left",
                {
                  "rounded-2xl bg-transparent border border-white":
                    value !== `item-${index}`,
                }
              )}
            >
              {item.name}
            </AccordionTrigger>
            <AccordionContent className="bg-white rounded-b-2xl px-6 py-4 -mt-px text-xl">
              <RichText html={renderRichText(item.text)} />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};

export default FaqSection;
