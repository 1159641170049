import { renderRichText } from "@storyblok/react";
import { Headline } from "@/components/typography/Headline";
import type { TextContentStoryblok } from "@/component-types-sb";

import { RichText } from "@/components/typography/RichText";

type TextContentProps = {
  blok: TextContentStoryblok;
};

const TextContent = ({ blok }: TextContentProps) => (
  <>
    <Headline as="h4" size="medium" className="mb-16">
      {blok.headline}
    </Headline>
    <RichText html={renderRichText(blok.text)} className="text-xl mb-4" />
  </>
);

export default TextContent;
