"use client";

import Link from "next/link";
import type { ComponentProps, MouseEvent } from "react";
import { useRouter } from "next/navigation";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/typography/Icon";
import type { IconName } from "@/components/typography/Icon";
import { cn } from "@/lib/utils";

type TagProps = {
  label: string;
  href?: string;
  icon?: IconName;
  size?: "normal" | "small";
  active?: boolean;
} & Omit<ComponentProps<typeof Link>, "href">;

const Tag = ({
  label,
  href,
  icon,
  size = "normal",
  active = false,
  ...rest
}: TagProps) => {
  const router = useRouter();

  return (
    <Button
      variant="outline"
      size="sm"
      className={cn(
        "rounded-full bg-transparent border-black px-6 py-4 h-10 text-base",
        {
          ["bg-black text-white"]: active,
          ["px-4 py-3 h-8 text-xs"]: size === "small",
        },
        rest.className
      )}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (href) {
          e.preventDefault();
          router.push(href);
        }
      }}
    >
      {icon && <Icon name={icon} />}
      {label}
    </Button>
  );
};

export { Tag };
