import type { ReactNode } from "react";
import { cn } from "@/lib/utils";

type SublineSize = "tall" | "regular";

type SublineProps = {
  children: ReactNode;
  size?: SublineSize;
  className?: string;
};

const Subline = ({ children, size = "regular", ...rest }: SublineProps) => {
  return (
    <div
      className={cn(
        "text-xs uppercase",
        {
          ["text-xl"]: size === "tall",
        },
        rest.className
      )}
    >
      {children}
    </div>
  );
};

export { Subline };
