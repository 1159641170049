import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Headline } from "@/components/typography/Headline";
import { CopyText } from "@/components/typography/CopyText";
import type {
  FeatureListTextStoryblok,
  CtaStoryblok,
} from "@/component-types-sb";

type FeatureListTextProps = {
  blok: FeatureListTextStoryblok;
};

const FeatureListText = ({ blok }: FeatureListTextProps) => (
  <section id={blok._uid} {...storyblokEditable(blok)}>
    <header>
      <Headline as="h2" size="tall" className="font-bold">
        {blok.headline}
      </Headline>
    </header>

    <CopyText size="tall" className="max-w-4xl">
      {blok.text}
    </CopyText>

    <div className="grid md:grid-cols-3 gap-8 pt-8">
      {blok?.items?.map((nestedBlok: any) => (
        <div
          key={nestedBlok._uid}
          className="w-full flex flex-col items-start justify-between"
        >
          <StoryblokComponent blok={nestedBlok} style="text" />
        </div>
      ))}
    </div>

    <div className="flex flex-col items-center">
      {blok.cta?.map((nestedBlok: CtaStoryblok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </section>
);

export default FeatureListText;
