import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

export const Shape3 = ({ ...rest }: SvgProps) => (
  <svg
    width="532"
    height="272"
    viewBox="0 0 532 272"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M44 272c67.537 0 122.287-60.889 122.287-136C166.287 60.89 111.537 0 44 0v272ZM166.287 272C233.507 272 288 211.111 288 136 288 60.89 233.507 0 166.287 0v272ZM385.6 136c0 75.111-21.849 136-48.8 136-26.951 0-48.8-60.889-48.8-136 0-75.11 21.849-136 48.8-136 26.951 0 48.8 60.89 48.8 136Z"
    />
    <path
      fill="currentColor"
      d="M532 136c0 75.111-39.6 136-88.45 136s-88.45-60.889-88.45-136c0-75.11 39.6-136 88.45-136S532 60.89 532 136Z"
    />
  </svg>
);
