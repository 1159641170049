import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";
import { track } from "@vercel/analytics";

import type { AdStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

type AdProps = {
  blok: AdStoryblok;
};

const Ad = ({ blok }: AdProps) => {
  const ad = (blok.references?.[0] as AdStoryblok | undefined)?.content || blok;

  return (
    <Link
      {...storyblokEditable(ad)}
      href={ad.link?.cached_url || "#"}
      target={ad.link?.target || "_self"}
      onClick={() => {
        track("Ad click", { uid: ad._uid });
      }}
      aria-label="Werbung anzeigen"
      className={cn({
        ["lg:max-w-96 lg:fixed lg:top-48"]: ad.sticky,
      })}
    >
      {ad.image && (
        <Image
          src={ad.image.filename}
          alt={ad.image.alt || ""}
          className="w-full"
          width={970}
          height={90}
        />
      )}
    </Link>
  );
};

export default Ad;
