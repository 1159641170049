import { StoryblokComponent } from "@storyblok/react/rsc";

import type { FeatureListStoryblok } from "@/component-types-sb";

type FeatureListProps = {
  blok: FeatureListStoryblok;
};

const FeatureList = ({ blok }: FeatureListProps) => (
  <ol className="p-4 md:p-14 grid md:grid-cols-3 gap-6">
    {blok.items?.map((nestedBlok) => (
      <StoryblokComponent
        key={nestedBlok._uid}
        blok={nestedBlok}
        variant="list"
      />
    ))}
  </ol>
);

export default FeatureList;
