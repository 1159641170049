"use client";

import React from "react";
import type { VideoHTMLAttributes } from "react";

import { useDetectBrowser } from "@/lib/hooks/useDetectBrowser";
import { cn } from "@/lib/utils";

type AnimationProps = VideoHTMLAttributes<HTMLVideoElement>;

const Animation: React.FC<AnimationProps> = ({ ...rest }) => {
  const browserName = useDetectBrowser();

  return (
    <>
      <video
        id="shape-video"
        autoPlay
        muted
        playsInline
        loop
        {...rest}
        className={cn(
          "hidden md:block w-full h-full object-contain",
          {
            ["brightness-[0.98]"]: browserName === "safari",
          },
          rest.className
        )}
      >
        <source src="/videos/shape.webm" type="video/webm" />
        <source src="/videos/shape.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video
        id="shape-video-mobile"
        autoPlay
        muted
        playsInline
        loop
        {...rest}
        className={cn(
          "md:hidden w-full h-full object-contain",
          {
            ["brightness-[0.98]"]: browserName === "safari",
          },
          rest.className
        )}
      >
        <source src="/videos/shape_mobile.webm" type="video/webm" />
        <source src="/videos/shape_mobile.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export { Animation };
